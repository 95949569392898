.input {
  display: flex;
  width: 100%;
  height: 32px;
  border: 1px solid var(--color-border);
  padding: 3px;
  box-sizing: border-box;
}

.color {
  width: 100px;
  height: 24px;
  margin-right: 10px;
}

.ul {
  list-style: none;
  display: flex;
  padding: 0;
}

.li {
  width: 10%;
  height: 26px;
}
